import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/design.svg";

const CreativeDesign = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-design.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      fabio: file(relativePath: { eq: "profiles/fabio-baser.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.design.link">
      <Seo
        title="Creative design – Monday Consulting"
        description="Our creative design experts give your website poise and polish. We develop a brand new look that is visually impressive while being based on a sound methodology."
        keywords="creative design, Atomic, multi-channel, storybook, living style guide"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Looking good on all your channels"
        subline="We keep an eye on the code when working on your design."
        section="Creative design"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Creative design"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Creativity and tech in perfect harmony"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Creativity and tech in perfect harmony</h2>
            <p>
              We give your website poise and polish. Whatever your project brief
              – whether you need a new design, a modification of your existing
              corporate web design or something to help you expand across new
              output channels – we handle your creative design work from start
              to finish.
            </p>
            <p>
              Our creative experts develop a (brand new) look for your content
              that is visually impressive while being based on a sound
              methodology. We use atomic design, which lets us create building
              blocks from all of the individual elements. By following this
              approach, we can implement new requirements from your corporate
              design quickly and straightforwardly.
            </p>
            <p>
              To streamline our work with customers, our designers and
              developers work together to create a living style guide based on a
              storybook. This storybook includes all of the elements and
              examples of how to link them together, so they are ready-to-go for
              your system at any time.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">Creative design in detail</h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-multichannel bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Multi-channel
            </h3>
            <p>
              Whether your content is consumed on phones, tablets or desktops,
              our design takes care of all the channel-specific challenges to
              offer maximum, device-neutral flexibility.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-atomicdesign bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Atomic design
            </h3>
            <p>
              We separate out all of your design components and create a
              repository of all the elements needed. This ensures that we can
              access the individual components at any time, combining these
              together easily to meet new requirements and thereby minimizing
              the effort needed for landing pages (for example).
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-styleguide bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Living style guide
            </h3>
            <p>
              At the heart of each project is the living style guide, which we
              create, update and maintain as a storybook together with the
              development team. This gives you the chance to check through all
              of your elements and finished page types before coding starts.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-design-migration bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Design migration
            </h3>
            <p>
              Already working together with a creative agency or have a mature
              corporate design that needs to be applied now but developed
              further in the future? Not a problem! Our seasoned design teams
              are ready to handle any eventuality.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="swp,gdv" />
      </div>

      <ContactPerson
        headline="Interested?"
        text="Are you facing a similar challenge in your company? Talk to us today about your needs and requirements. Our experts look forward to helping you."
        contactEmail="frontend@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Fabio Baser, Unit Lead Frontend"
      >
        <GatsbyImage
          image={images.fabio.childImageSharp.gatsbyImageData}
          alt="Fabio Baser"
        />
      </ContactPerson>
    </Layout>
  );
};

export default CreativeDesign;
